import React from 'react';
import {Helmet} from "react-helmet";

// Components
import HeaderV2 from "../components/HeaderV2/HeaderV2";
import ProjectList from "./ProjectList";
import Footer from "../components/Footer/Footer";
import ContactUsBtn from "../components/ContactUsBtn";


// Css / images
import "../../styles/projects.scss";
import SEO from "../components/SEO";

function Projects() {

    return (
        <div>
            <SEO title={"Projects - QCTesting"} description={"List of projects Qctesting has been a part of"} url={"www.qctesting.com.au/Projects"}/>
            <div>
                <HeaderV2/>
                <div className={"projectsHeaderContainer"}>
                    <h1>Projects Completed</h1>
                    <div className={"break"}/>
                    <p>
                        Below are a few Projects that we have completed, we have worked alongside many companies involved in Main Rds.
                        Projects, Level 1 Earthworks supervision, Quarry testing for Quality control concrete testing for Quality control and ongoing design for council Rds and projects
                    </p>
                </div>
                <ProjectList/>
                <Footer/>
                <div /* Fix Position Items  */ >
                    <ContactUsBtn className={"contactBtnPosition"} redirect={"Tel:0408623377"}/>
                </div>
            </div>

        </div>
    );
}

export default Projects;
