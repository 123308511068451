import React from 'react';
import "../../styles/projects.scss"

import  {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

function ProjectList() {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "ProjectImages"}}) {
                nodes {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            quality: 80
                        )
                    }
                    name
                }
            }
        }
    `);
    const items = [{
        id:0,
        title: "Hazel Bros: Scotchy Pocket Quarry (Curra)",
        img: "https://drive.google.com/uc?export=view&id=1t5sk3PN0PwZHKr5oukqHOna0WaEj0GZ4",
        category: "Concrete",
        desc: "various main rds tests were done on these projects"
    }];
    let len = data.allFile.length / 2;
    const leftHalf = data.allFile.nodes.slice(len, items.length)
    const rightHalf = data.allFile.nodes.slice(0,len)

    const inViewport = (entries : any) => {
        entries.forEach((entry : any) => {
            entry.target.classList.toggle("isInViewport", entry.isIntersecting);
        });
    };
    React.useEffect(() => {
        let Obs = new IntersectionObserver((inViewport));
        const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
        ELs_inViewport.forEach(EL => {
            Obs.observe(EL);
        });
    })

    return (
            <div className={"projectCardsContainer"}>
                <div >
                    {leftHalf.map((items : any) => (
                        <div data-inviewport="leftContents" className={"left"} key={items.id}>
                            <h2 >{items.name}</h2>
                            <div className={"break"}/>
                            <GatsbyImage
                                image={items.childImageSharp.gatsbyImageData}
                                alt={items.name}
                            />
                            <div className={"break"}/>
                        </div>
                    ))}
                </div>
                <div >
                    {rightHalf.map((items : any) => (
                        <div data-inviewport="rightContents" className={"right"} key={items.id}>
                            <h2>{items.name}</h2>
                            <div className={"break"}/>
                            <GatsbyImage
                                image={items.childImageSharp.gatsbyImageData}
                                alt={items.name}
                            />
                            <div className={"break"}/>
                        </div>
                    ))}
                </div>
            </div>
    );
}

export default ProjectList;
